/* manrope-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Manrope Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/manrope-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/manrope-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/manrope-v13-latin-regular.ttf') format('truetype') /* Safari, Android, iOS */
}
/* manrope-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Manrope Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/manrope-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/manrope-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/manrope-v13-latin-700.ttf') format('truetype') /* Safari, Android, iOS */
}