$color-bg-input:  #f6f9fc;
$color-input-text: #425c9e;

label {
  font-family: $font-family-regular;
  cursor: none;
  -webkit-tap-highlight-color: transparent;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-field {
  margin-bottom: 15px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.form-field__wrapper--input-checkbox {
  position: relative;
}

input[type='radio'] {
  -moz-appearance: textfield;
}

.input-text,
.input-textarea {
    border: 1px solid #e2e3e3;
}

.input-text {
  font-family: $font-family-regular;
  font-weight: 500;
  appearance: none; // Removes awkward default styles on some inputs for iOS
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 1126.87px;
  color: rgba(255, 255, 255, 0.68);
  font-size: 14px;
  height: 46px;
  padding: 0 15px;
  background-image: none !important;
}

.form-field {
  // general wrapper for form-field elements
  &__wrapper {
    margin-bottom: 20px;

    &.has-error {
      label {
        color: #a94442;
      }

      input {
        color: #a94442;
        border-color: #a94442;
      }
    }

    &--text,
    &--password,
    &--select,
    &--number {
      position: relative;
    }

    &-textarea {
      border: 0;
      position: relative;
    }
  }

  // general label-styling in form-fields
  &__label {
    font-family: $font-family-regular;
    font-size: 13px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
    color: #6B7280;

    &.has-error {
      color: #a94442;
    }


    // label behavior in combination with text- and password-inputs
    .form-field__wrapper--number &,
    .form-field__wrapper--text &,
    .form-field__wrapper--password & {
      @include transition($duration: .15s);
      font-size: 14px;
      font-weight: 500;
      background-color: $color-bg-input;
      color: rgba(255, 255, 255, 0.68);
      opacity: .78;
      position: absolute;
      padding: 0 5px;
      left: 10px;
      top: -10px;

      &:hover {
        cursor: text;
      }
    }
  }
}