/**
 * Container
 */

.container,
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container {
  @include make-responsive(max-width, $container-max-widths);
  box-sizing: border-box;
}