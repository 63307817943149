.footer {
    margin: 300px auto 25px;
    text-align: center;
    position: relative;

    @include media-breakpoint-down(md) {
        margin-top: 150px;
        margin-bottom: -100px;
    }

    &::before {
        content: '';
        display: block;
        background-image: url('../../assets/img/footer.png');
        background-position: top center;
        background-repeat: no-repeat;

        width: 100%;
        height: 300px;

        position: absolute;
        top: -128px;
        left: 0;

        @include media-breakpoint-down(md) {
            top: -50px;
            height: 200px;
        }
    }

    .w-full {
        z-index: 99;
    }


    p {
        font-family: $font-family-bold;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.14px;
        color: rgba(255, 255, 255, 0.56);
        display: block;

        margin-bottom: 15px;
    }

    &__nav {
        a {
            font-family: $font-family-bold;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.14px;
            color: rgba(255, 255, 255, 0.75);

            margin-bottom: 15px;

            &:not(:first-child) {
                margin-left: 20px;
            }
        }
    }

    &__social {
        margin: 15px auto 50px;

        a {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}