.learn-more {
    margin: 200px 0 0 0;
    display: block;
    height: 100%;
    position: relative;


    &::before {
        content: '';
        background-image: url('../../assets/img/hero-background-bottom.svg');
        background-position: top center;
        background-repeat: no-repeat;

        width: 100%;
        height: 500px;

        position: absolute;
        top: -500px;

        @include media-breakpoint-down(md) {
            top: -400px;
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 400px;
    }

    .limiter {
        position: relative;
        overflow: hidden;
    }

    &__head {
        text-align: center;
    }

    .features__box {
        list-style: none;
        max-width: 1180px;
        margin: 100px auto 0;
        position: relative;

        &::before {
            content: '';
            width: 2px;
            height: 100%;
            background: rgba(255, 255, 255, 0.12);
            border-radius: 25px;
            display: block;

            position: absolute;
            left: 50%;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &-item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 100px;
            align-items: center;

            padding-bottom: 75px;

            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 0;
                padding-bottom: 0;
                margin-bottom: 100px;
            }

            h3 {
                font-family: $font-family-headings;
                font-weight: 700;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: -0.14px;
                color: $color-white;

                display: block;

                margin-bottom: 15px;

                @include media-breakpoint-down(xs) {
                    font-size: 20px;
                    line-height: 26px;
                    display: block;
                }

                span {
                    color: #00FFB2;
                }
            }

            img {
                margin: 0 auto ;
                z-index: 99999;
                position: relative;
                bottom: -40px;

                &.ai {
                    bottom: -80px;
                }

                &.marketplace {
                    bottom: -100px;
                }
            }
        }

        .listing {
            @include media-breakpoint-down(md) {
                margin-top: 70px;
            }
        }

        &-wrap {
            background: linear-gradient(193.02deg, rgba(0, 255, 178, 0.1) 9.39%, rgba(0, 255, 178, 0) 94.51%);
            backdrop-filter: blur(2px);
            /* Note: backdrop-filter has minimal browser support */

            border-radius: 9px;
            /* Note: backdrop-filter has minimal browser support */

            border-radius: 15.4px;
            padding: 100px 40px 40px;
            z-index: 1;

            position: relative;

            img {
                position: absolute;
                right: 20px;
                top: 20px;
            }

            p {
                font-family: $font-family-regular;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.14px;
                color: rgba(255, 255, 255, 0.38);
                display: block;
                text-align: left;
            }
        }
    }
}

.progress-bar {
    width: 2px;
    height: 100%;
    background: #00FFB2;
    border-radius: 25px;
    display: block;

    position: absolute;
    left: 50%;
    margin-top: 80px;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}