.hero {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-top: -450px;

    background-image: url('../../assets/img/stars.svg');
    background-position: center center;
    background-repeat: no-repeat;

    position: relative;

    z-index: 0;

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        padding: 0 15px;
        height: 100%;
        margin-top: -25px;
    }

    &__header {
        position: absolute;
        max-width: 40vw;
        margin: 0 auto;
        text-align: center;
        margin-top: -290px;

        @include media-breakpoint-between(md, xl) {
            max-width: 70vw;
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            position: relative;
            margin-top: 0;
        }

        &__headline {
            font-family: $font-family-headings;
            font-weight: 700;
            font-size: 60px;
            line-height: 81px;
            color: $color-white;

            display: block;

            margin-bottom: 50px;

            background: linear-gradient(180deg, #FFFFFF 22.5%, rgba(255, 255, 255, 0.7) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            @include media-breakpoint-down(xs) {
                text-align: center;
                font-size: 50px;
                line-height: 60px;
                display: block;
            }
        }

        &__btns {
            display: flex;
            align-items: center;
            justify-content: center;


            .btn {
                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }
}