.mobile--hidden {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.mobile-large--hidden {
    @include media-breakpoint-down(xl) {
        display: none !important;
    }
}

.desktop-small--hidden {
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}

.desktop-large--hidden {
    @include media-breakpoint-up(xl) {
        display: none !important;
    }
}

.desktop--hidden {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

strong {
    font-family: $font-family-bold;
}

.is-visible {
    opacity: 1 !important;
    visibility: visible !important;
    z-index: 999 !important
}