.contact-bar {
    max-width: 500px;
    background: rgba(253, 253, 253, 0.06);
    border: 1px solid rgba(253, 253, 253, 0.03);
    backdrop-filter: blur(25px);

    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-self: unset;

    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);

    padding: 5px 10px;
    z-index: 999;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    svg {
        margin-right: 10px;
    }

    p {
        font-family: $font-family-bold;
        font-size: 14px;
        line-height: 22px;
        color: #CECECE;

        margin-right: 15px;
    }

    .btn {
        font-size: 14px;
        padding: 10px 20px;
    }
}