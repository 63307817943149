// /**
//  * Buttons
//  */
$btn-font-size: 14px !default;
$btn-font-size-mobile: 16px !default;

$btn-padding-x: 25px !default;
$btn-padding-y: 8px !default;

$line-height-base: 30px !default;


/* Common button styles */
.btn {
    font-family: $font-family-bold;
    font-weight: 600;
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    color: $color-white;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-size: $btn-font-size;
    line-height: $line-height-base;
    letter-spacing: .1px;
    line-height: 26px;
    user-select: none;
    text-decoration: none;
    border-radius: 7.7px;
    outline: 0;
    background: none;
    position: relative;
    z-index: 1;
    @include transition;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
    }

    &:hover {
        text-decoration: none !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px !important;
    }



    &--dashboard {
        transition: all .3s ease-out;
        font-size: 16px;
        line-height: 26px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgba(239, 247, 255, 0.616);
        border-radius: 999px;

        overflow: hidden;
        padding: 10px $btn-padding-x;

        background: #1E1E22;

        z-index: 1;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #1E1E22;
            border-radius: 10rem;
            z-index: -2;
        }


        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: #00FFB2;
            transition: all .3s ease-out;
            border-radius: 10rem;
            z-index: -1;
        }

        &:hover {
            color: #2C2C2C;

            &:before {
                width: 100%;
            }
        }
    }

    &--getstarted {
        transition: all .3s ease-out;
        font-size: 16px;
        line-height: 26px;
        border: 1px solid #00FFB2;
        color: #2C2C2C;
        border-radius: 999px;

        overflow: hidden;
        padding: 10px $btn-padding-x;

        background: #00FFB2;

        z-index: 1;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #00FFB2;
            border-radius: 10rem;
            z-index: -2;
        }


        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: #1E1E22;
            transition: all .3s ease-out;
            border-radius: 10rem;
            z-index: -1;
        }

        &:hover {
            color: rgba(239, 247, 255, 0.616);

            &:before {
                width: 100%;
            }
        }
    }
}