/**
 * Document defaults (html, body)
 */

 * {
    outline: 0;
    border: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
}


html {
    font-family: $font-family-regular;
    font-size: 14px;
    color: $color-white;
    line-height: $line-height-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

html::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}

body {
    background-color: $color-bg-default;
}