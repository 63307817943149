
.header {
	transition: all ease-out .2s !important;

	position: relative;
    z-index: 1;
	width: 100%;
	padding: 15px 0;
	width: 100%;

	&::after {
		content:'';
		background-image: url('../../assets/img/top.png');
		background-position: top center;
		height: 600px;
		display: block;
		margin-top: -150px;

		@include media-breakpoint-down(sm) {
			text-align: center;
			height: 250px;
			background-size: cover;
		}
	}

	@include media-breakpoint-down(sm) {
        text-align: center;
    }

	&__inner {
		display: flex;
		align-items: center;
	}

	.btn {
		&:not(:first-child) {
			margin-left: 20px;
		}
	}

	.align-center {
		align-items: center;
	}

	&__cta {
		text-align: right;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.logo {
	display: inline-block;
	text-decoration: none;

	display: flex;
	align-items: center;

	&__img {
		display: inline-block;
		width: 100%;
		max-width: 60px;
		height: 100%;
	}

	&__title {
		font-size: 14px;
		line-height: 19px;
		font-weight: 700;

		color: $color-white;
		font-family: $font-family-bold;

		margin-left: 5px;

		span {
			color: $color-white;
		}
	}

	&:hover {
		text-decoration: none !important;;
	}
}